import Image from "next/image"
import Typography, { TypographyProps } from "../../typography"
import { cx } from "class-variance-authority"

type IconTextProps = {
  text: string
  boldText?: string
  icon: string
  width?: number
  height?: number
  className?: string
  tooltip?: string
  textMargin?: string
} & TypographyProps

const IconText = ({
  text,
  textMargin,
  tooltip,
  boldText,
  icon,
  width = 20,
  height = 5,
  type,
  color,
  weight,
  className
}: IconTextProps) => {
  return (
    <div title={tooltip} className={cx("flex items-start gap-1 py-1", className)}>
      <Image src={icon} width={width} height={height} alt={text} />
      <Typography type={type} color={color} weight={weight} className={textMargin}>
        {boldText && <span className="font-tbcx-medium">{boldText} </span>}
        {text}
      </Typography>
    </div>
  )
}

export default IconText
