// ** Tailwind Styled
import tw from "tailwind-styled-components"

// VPL - Vacancy Page List
export const VacanciesListBox = tw.div`relative col-span-4 md:max-h-[732px] overscroll-contain md:pb-[44px]`

export const VacanciesListHeader = tw.div`flex justify-between items-center px-4 py-2.5 border-b border-neutral-40  bg-white `
export const VacanciesListHeaderSort = tw.div`relative items-center flex cursor-pointer gap-1`

export const VacanciesListBoxList = tw.div`
  md:max-h-full   
  md:overflow-y-auto 
  pb-4               // Adds padding to avoid items being cut off
  scroll-pb-20 
  overscroll-contain
`
// სქროლი წავშალე export const VacanciesListBoxList = tw.div`vacancy-list-scrollbar h-full overflow-y-scroll overscroll-contain `
